import React from "react"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton, RedditIcon, RedditShareButton,
  TwitterIcon,
  TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon
} from "react-share"

const SociaLShareButtons = ({ title, iconSize = 32, url = "" }) => {
  return (
    <div className="article-share">
      <ul className="social">
        <li style={{ margin: "3px" }}>
          <FacebookShareButton
            url={url}
          >
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>
        </li>
        <li style={{ margin: "3px" }}>
          <TwitterShareButton
            url={url}
            title={title}
          >
            <XIcon size={iconSize} round />
          </TwitterShareButton>
        </li>
        <li style={{ margin: "3px" }}>
          <LinkedinShareButton
            url={url}
            title={title}
          >
            <LinkedinIcon size={iconSize} round />
          </LinkedinShareButton>
        </li>
        <li style={{ margin: "3px" }}>
          <WhatsappShareButton
            url={url}>
            <WhatsappIcon size={iconSize} round />
          </WhatsappShareButton>
        </li>
        <li style={{ margin: "3px" }}>
          <EmailShareButton
            url={url}
            subject={title}
          >
            <EmailIcon size={iconSize} round />
          </EmailShareButton>
        </li>
      </ul>
    </div>
  )
}

export default SociaLShareButtons
