import React from 'react';

const SearchWidget = () => {
    return (
        <div className="widget widget_search">
            <h3 className="widget-title">Search</h3>

            <form className="search-form">
                <label>
                    <span className="screen-reader-text">Search for:</span>
                    <input type="search" className="search-field" placeholder="Search..."/>
                </label>
                <button type="submit">
                    <i className="bx bx-search-alt"/>
                </button>
            </form>
        </div>
    );
};

export default SearchWidget;
