import React from 'react';
import PopularPosts from "../PopularPosts";

const PopularPostsWidget = () => {
    return (
            <div className="widget widget_tracer_posts_thumb">
                <h3 className="widget-title">Populäre Artikel</h3>
                <PopularPosts/>
            </div>
    );
};

export default PopularPostsWidget;
