import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import PopularPostEntry from "./PopularPostEntry";

const PopularPosts = ({numberOfPosts = 3}) => {
    const data = useStaticQuery(
        graphql`
      query popularBlogPostQuery {
          allPageViews {
            nodes {
              totalCount
              id
            }
          }
          allMarkdownRemark (
            filter: { fields: { collection: { eq: "blog" }}}
          ) {
            nodes {
              id
              fields {
                slug
              }
              frontmatter {
                kurznews
                title
                featuredimage {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED)
                  }
                }
                featuredimage2: featuredimage {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 100)
                  }
                }
                date(formatString: "DD.MM.YYYY")
              }
            }
          }
        }`
    );

    const { allMarkdownRemark: {nodes: posts}} = data;
    const { allPageViews: {nodes: pageViews}} = data;
    //console.log(pageViews);
    //console.log(posts);
    const blogPostsWithHitDataFromGA = posts.filter(post => !post.frontmatter.kurznews).map((post) => (
        {...post, pageView: pageViews.find(pageView => pageView.id === post.fields.slug ||
              pageView.id === post.fields.slug + "index.html") || {totalCount: 0}}
    ));
    blogPostsWithHitDataFromGA.sort((a, b) => {
        return b.pageView.totalCount - a.pageView.totalCount
    }).splice(numberOfPosts);

    //console.log(blogPostsWithHitDataFromGA);
    return (
        <>
            {blogPostsWithHitDataFromGA.map((post) => (
                <PopularPostEntry key={post.id} post={post} />
            ))}
        </>
    );
}

export default PopularPosts;
