import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/App/Layout"
import BlogDetailsContent from "../components/BlogContent/BlogDetailsContent"
import Navbar from "../components/App/Navbar"
import { Helmet } from "react-helmet"
import logentisLogo from "../assets/images/logo.svg"
import Footer from "../components/App/Footer"
import PageBanner from "../components/Common/PageBanner"
import LexikonBadgesRandomSection from "../components/lexikon/LexikonBadgesRandomSection"

const BlogSingle_page = ({ data, pageContext, location: { origin } }) => {
  const { markdownRemark: post, site: { siteMetadata: { siteUrl } } } = data
  const fullUrl = siteUrl + post.fields.slug
  return (
    <Layout>
      <Helmet titleTemplate={`%s`}>
        <title>{post.frontmatter.title}</title>
        <meta name={"description"} content={post.frontmatter.description} />
        <meta name={"image"} content={siteUrl + post.frontmatter.socialmediaimage.childImageSharp.resize.src} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.frontmatter.title} />
        <meta name="twitter:description" content={post.frontmatter.descriptionTwitter || post.frontmatter.description} />
        <meta name="twitter:image" content={siteUrl + post.frontmatter.socialmediaimage.childImageSharp.resize.src} />

        <meta property={"og:description"} content={post.frontmatter.description} />
        <meta property={"og:image"} content={siteUrl + post.frontmatter.socialmediaimage.childImageSharp.resize.src} />
        <meta property={"og:title"} content={post.frontmatter.title} />

        <script type="application/ld+json">{`
                    { 
                         "@context": "https://schema.org", 
                         "@type": "BlogPosting",
                         "headline": "${post.frontmatter.title}",
                         "image": "${origin}${post.frontmatter.socialmediaimage.childImageSharp.resize.src}",
                         "editor": "${post.frontmatter.authorFull.name}", 
                         "genre": "${post.frontmatter.title}", 
                         "keywords": "${post.frontmatter.tags}", 
                         "wordcount": "${post.wordCount.words}",
                         "publisher": {
                            "@type": "Organization",
                            "name": "LOGENTIS GmbH",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "${origin}${logentisLogo}"
                            }
                         },
                         "url": "${fullUrl}",
                         "datePublished": "${post.frontmatter.jsonld_date}",
                         "dateCreated": "${post.frontmatter.jsonld_date}",
                         "description": "${post.frontmatter.description}",
                         "articleBody": "${post.jsonld.replaceAll("\"", "'")}",
                         "author": {
                            "@type": "Person",
                            "name": "${post.frontmatter.authorFull.name}"
                         }
                    }
                `}</script>

      </Helmet>
      <Navbar />
      <PageBanner
        pageTitle="Blog"
        homePageText="Home"
        homePageUrl="/"
        makeH1={false}
        bannerClassname={"page-title-area pb-4 pt-70"}
        activePageText="Blog"
      />
      <BlogDetailsContent
        tagCountsArray={pageContext.tagCountsArray}
        categoryCountsArray={pageContext.categoryCountsArray}
        withInfo={false}
        category={post.frontmatter.category}
        withSidebar={true}
        htmlContent={post.html}
        author={post.frontmatter.authorFull}
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        image={post.frontmatter.socialmediaimage}
        date={post.frontmatter.date}
        title={post.frontmatter.title}
        url={fullUrl} />
      <section className="testimonials-area bg-fafafb">
        <div className="container pt-70 pb-70">
          <div className="section-title">
            <h3 className={"h3"}>Mehr aus unserem Lexikon</h3>
            <p>Einige Artikel aus der Rubrik "Logistik / Allgemein"</p>
          </div>
          <LexikonBadgesRandomSection product={"Allgemein"}/>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default BlogSingle_page

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
        siteMetadata {
            siteUrl
        }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 400)
      jsonld:excerpt(format: PLAIN, pruneLength: 4000)
      wordCount {
         words
      }
      internal {
        content
      }
      html
       fields {
        slug
      }
      frontmatter {
        date: date(formatString: "DD.MM.YYYY · HH:mm")
        jsonld_date: date(formatString: "YYYY-MM-DD")
        title
        description
        descriptionTwitter
        tags
        featuredimage {
          childImageSharp {
            gatsbyImageData
          }
        }
        authorFull {
            email
            name
            shortbio
            title
            authorimage {
              childImageSharp {
                gatsbyImageData(width: 100, placeholder: BLURRED)
              }
            }
        }
        socialmediaimage {
          childImageSharp {
            gatsbyImageData
            resize(width: 1200) {
              src
            }
          }
        }
      }
    }
  }
`
