import React from 'react';
import {Link} from "gatsby";
import {GatsbyImage} from 'gatsby-plugin-image'

const PopularPostEntry = ({post}) => {
    return (
        <Link key={post.id} to={post.fields.slug} className="neutral-link-optional-color">
            <article className="item" style={{position: "relative", overflow: "hidden"}}>
                <div style={{position: "absolute", top: 0, left: 0, width:"100px", zIndex: "2"}}>
                    <div style={{overflow: "hidden"}}>
                        <GatsbyImage
                          image={post.frontmatter.featuredimage2.childImageSharp.gatsbyImageData}
                          style={{width: "100%", height: "100%"}}
                          alt={"something"}
                          objectFit={"cover"}
                        />
                    </div>
                </div>

                <div className="info" style={{boxSizing: "border-box", position: "relative", minHeight: "100px",
                    paddingLeft: "120px"}}>
                    <div style={{fontSize: "14px"}}>{post.frontmatter.date}</div>
                    <h4 style={{fontSize: "18px"}}>
                        {post.frontmatter.title}
                    </h4>
                </div>
            </article>
        </Link>
    );
};

export default PopularPostEntry;
