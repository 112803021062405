import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BlogSidebar from "./BlogSidebar"
import Content from "./Content"
import img11 from "../../assets/images/error.png"
import img12 from "../../assets/images/error.png"
import authorFailover from "../../assets/images/error.png"
import { Link } from "gatsby"
import BlogPostMetaArea from "./BlogPostMetaArea"
import SociaLShareButtons from "./SocialShareButtons"

const BlogDetailsContent = ({
                              withSidebar = true,
                              withInfo = true,
                              content,
                              htmlContent,
                              category,
                              author,
                              description,
                              tagCountsArray,
                              categoryCountsArray,
                              tags,
                              imagePreview,
                              image,
                              date,
                              title,
                              url
                            }) => {


  // temp fix because preview mode with relations is broken in Netlify CMS
  if (typeof author === "string") {
    author = {
      name: "Some Name", authorimage: "", title: "Some Title", shortbio: "short bio"
    }
  }

  const { name: authorName, authorimage: authorImage, title: authorTitle, shortbio: shortBio } = author

  return (
    <section className="blog-details-area pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="blog-details-desc">
              <div className="article-image">
                {imagePreview && <img src={imagePreview} alt={title + " Img"} />}
                {image && <GatsbyImage alt={title} image={getImage(image)} />}
              </div>

              <div className="article-content">
                <BlogPostMetaArea date={date} tags={tags} />

                <Content content={content} htmlContent={htmlContent} className={"entry-content"} />

              </div>


              <div className="article-footer">
                <div className="article-tags">
                  &nbsp;
                </div>

                <SociaLShareButtons title={title} url={url} />
              </div>

              <div className="article-author">
                <div className="author-profile-header" />
                <div className="author-profile">
                  <div className="author-profile-title">
                    {authorImage.childImageSharp &&
                      <GatsbyImage alt={authorName + " Foto"}
                                   image={getImage(authorImage)} />}
                    {!authorImage.childImageSharp && <img alt="unknown" src={authorFailover} />}
                    <h4>{authorName}</h4>
                    <span className="d-block">{authorTitle}</span>
                    <p>{shortBio}</p>
                  </div>
                </div>
              </div>

              {withInfo &&
                <div className="tracer-post-navigation">
                  <div className="prev-link-wrapper">
                    <div className="info-prev-link-wrapper">
                      <Link to="#">
                                            <span className="image-prev">
                                                <img src={img11} alt="bb" />
                                                <span className="post-nav-title">Prev</span>
                                            </span>

                        <span className="prev-link-info-wrapper">
                                                <span
                                                  className="prev-title">What Is The MLB Summer Slugger Program?</span>
                                                <span className="meta-wrapper">
                                                    <span className="date-post">January 21, 2020</span>
                                                </span>
                                            </span>
                      </Link>
                    </div>
                  </div>


                  <div className="next-link-wrapper">
                    <div className="info-next-link-wrapper">
                      <Link to="#">
                                            <span className="next-link-info-wrapper">
                                                <span className="next-title">28 Student-Centered Instructional Strategies</span>
                                                <span className="meta-wrapper">
                                                    <span className="date-post">January 19, 2020</span>
                                                </span>
                                            </span>

                        <span className="image-next">
                                                <img src={img12} alt="bb" />
                                                <span className="post-nav-title">Next</span>
                                            </span>
                      </Link>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>

          {withSidebar &&
            <div className="col-lg-4 col-md-12">
              <BlogSidebar tagCountsArray={tagCountsArray} categoryCountsArray={categoryCountsArray} />
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default BlogDetailsContent
